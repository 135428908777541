<template>
    <v-slide-group
        :key="String(reset)"
        v-intersect.once="onObserve"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <v-slide-item
            v-for="(card, i) in filtered"
            :key="i"
            v-slot:default="{ active, toggle }"
        >
            <div>
                <project-card
                    :key="i"
                    :active="active"
                    :height="height"
                    :src="card.image"
                    :width="width"
                    v-bind="card"
                    class="mx-3"
                    @click="toggle"
                />
            </div>
        </v-slide-item>
    </v-slide-group>
</template>

<script>
export default {
    name: "SectionProjects",

    components: {
        ProjectCard: () => import("@/components/ProjectCard"),
    },

    props: {
        filter: {
            type: Function,
            default: (v) => !!v,
        },
        height: {
            type: [Number, String],
            default: 125,
        },
        width: {
            type: [Number, String],
            default: 175,
        },
    },

    data: () => ({
        cards: [
            {
                title: "Fair PDX",
                subtitle: "Listing Managment",
                category: "Listing Management",
                image: require(`@/assets/project-1.jpg`),
            },
            {
                title: "Management Partnerships",
                subtitle: "Listing Management",
                category: "Listing Management",
                image: require(`@/assets/project-2.jpg`),
            },
            {
                title: "Financial Insight",
                subtitle: "Screening Reports",
                category: "Screening Reports",
                image: require(`@/assets/project-3.jpg`),
            },
            {
                title: "Scorecards",
                subtitle: "Screening Reports",
                category: "Screening Reports",
                image: require(`@/assets/project-4.jpg`),
            },
            {
                title: "Accessible Data Entry",
                subtitle: "Online Application",
                category: "Online Application",
                image: require(`@/assets/project-5.jpg`),
            },
            {
                title: "Easy Starting Point",
                subtitle: "Online Application",
                category: "Online Application",
                image: require(`@/assets/project-6.jpg`),
            },
            {
                title: "Advanced Design",
                subtitle: "Administrative Dashboard",
                category: "Administrative Dashboard",
                image: require(`@/assets/project-7.jpg`),
            },
            {
                title: "Comprehensive Processing",
                subtitle: "Administrative Dashboard",
                category: "Administrative Dashboard",
                image: require(`@/assets/project-8.jpg`),
            },
        ],
        reset: false,
    }),

    computed: {
        filtered() {
            return this.cards.filter(this.filter);
        },
    },

    methods: {
        onObserve(x, y, isVisible) {
            if (this.reset) return;

            // Workaround for now
            // Issue lies within the slide-group's
            // logic to re-calculate the container's
            // overflow
            setTimeout(() => {
                this.reset = !this.reset;
            }, 100);
        },
    },
};
</script>
